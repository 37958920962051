/* Add to top of file */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: auto;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(to bottom, #7ed4fc, #0288D1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;  /* Changed from center to flex-start */
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Roboto', sans-serif;
  padding-top: 2rem;  /* Reduced from 4rem */
}

@media screen and (min-width: 768px) {
  .App-header {
    padding-top: 4rem; /* Keep original padding for desktop */
  }
}

h1 {
  margin-bottom: 1rem;
  font-size: 3rem;
}

.company-name {
  font-family: 'Orbitron', sans-serif;
  margin-bottom: 1.5rem;
  margin-top: 4rem;  /* Added to reduce space above */
  font-size: 4.5rem;
  text-align: center;
}

.coming-soon {
  margin-top: 2rem;
  font-size: 1.2rem;
  padding: 0 1rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Orbitron';
  src: url('./fonts/Orbitron-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

/* Mobile Responsive Design */
@media screen and (max-width: 768px) {
  .company-name {
    font-size: 3rem;
  }
  
  .coming-soon {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .company-name {
    font-size: 2.5rem;
  }
  
  .App-header {
    padding: 0.5rem;
    font-size: calc(8px + 2vmin);
  }
}

.subscribe-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
}

.subscribe-form input,
.subscribe-form button {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.subscribe-form button {
  background-color: #ffffff;
  color: #2596be;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.subscribe-form button:hover {
  background-color: #f0f0f0;
}

.success-message {
  color: #ffffff;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .subscribe-form {
    padding: 0 1rem;
  }
}

.social-links {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
}

.social-links a {
  color: white;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #f0f0f0;
}

@media screen and (max-width: 480px) {
  .social-links {
    top: 10px;
    right: 10px;
    gap: 10px;
  }
}

.error-message {
  color: #ffffff;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}
